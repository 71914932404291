<template>
  <page-record-offline v-if="isRecordOffline"></page-record-offline>
  <page-not-found v-else-if="isRecordNotExist"></page-not-found>
  <viewer-page
    v-else
    ref="viewer-page"
    :prevRoute="previousRoute || defaultPreviousRoute"
    :prevRouteLabel="previousPageLabel"
    :current-page="currentPage"
    :current-page-ocr-data="currentPageOcrData"
    :loading="sourceDetailsLoadingState"
    :loadingPage="sourceImageLoadingState"
    :loadingOcr="sourceImageOcrLoadingState"
    :loaded="detailsLoaded"
    :pagesTotalCount="sourceDetailsState.images_count"
    :searchTotalCount="sourceImagesSearchMetaState.total_count"
    :search-value="searchValue"
    :user-has-full-access="sourceDetailsState && sourceDetailsState.user_has_full_access"
    :current-page-number="currentPageNumber"
    :show-ocr-button="showOcrButton"
    :init-show-dict="sourceDetailsState.has_ocr"
    :get-thumbnail-src="getThumbnailSrc"
    @search="onSearchFromBar"
    @change-page="onChangePage"
    @select-ocr-type="onSelectOcrType"
  >
    <base-source-details-info
      slot="sidebar-details"
      slot-scope="props"
      :source="sourceDetailsState"
      :details-route="detailsRoute"
      @closeDetails="props.setShowSidebar(false)"
    >
      <div slot="actions">
        <mcr-button class="get-help-button" @click="onGetHelpClick">Get Translation Help</mcr-button>
        <save-zupu-button
          v-if="sourceDetailsState.zupu_id"
          :is-saved="sourceDetailsState.is_user_saved"
          :zupu-id="sourceDetailsState.zupu_id"
        ></save-zupu-button>
      </div>
      <admin-controls slot="extra" :image-id="currentPage.id" v-if="userIsStaffState"></admin-controls>
    </base-source-details-info>

    <source-search-results-container
      slot="sidebar-search-results"
      slot-scope="props"
      @close="
        props.setShowSidebar(false);
        clearSearch();
      "
      @search-result-click="
        props.setNormalSidebarWidth();
        searchResultClick($event);
      "
      @search="onSearch"
      :value="searchValue"
      :loading="sourceImagesSearchLoadingState"
      :results="searchResults"
      :results-meta="sourceImagesSearchMetaState"
    ></source-search-results-container>

    <div slot="description-panel" class="description-text">
      <span>{{ currentPage.description || 'No Description' }}</span>
    </div>

    <template v-slot:preview-unavailable>
      <preview-unavailable
        v-if="sourceDetailsState.is_restricted && currentPage.id && !currentPage.url"
      ></preview-unavailable>
      <preview-unavailable-living-person
        v-else-if="sourceDetailsState.hide_living_persons && currentPage.id && !currentPage.url"
      ></preview-unavailable-living-person>
    </template>

    <template slot="modals">
      <add-mention-modal
        v-if="userIsStaffState"
        :source-image-id="currentPage.id"
        :source-id="sourceId"
      ></add-mention-modal>
    </template>
  </viewer-page>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import PreviewUnavailableLivingPerson from '@common/elements/layouts/book-viewer/PreviewUnavailableLivingPerson';
import ViewerPage from '@common/elements/layouts/book-viewer/ViewerPage';
import {getSourceImageThumbnailResolveURL} from '@common/network/network.utils';
import PageRecordOffline from '@common/pages/pageRecordOffline';
import BaseSourceDetailsInfo from '@common/pages/viewer/BaseSourceDetailsInfo';
import SourceSearchResultsContainer from '@common/pages/viewer/SourceSearchResultsContainer';
import sourceViewerPage from '@common/pages/viewer/js/source-viewer-page';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import {mapGetters} from 'vuex';

import SaveZupuButton from '@/components/common/buttons/SaveZupuButton';

import PreviewUnavailable from '@/components/modules/sourceViewer/PreviewUnavailable';
import AddMentionModal from '@/components/modules/sourceViewer/admin/AddMentionModal';
import AdminControls from '@/components/modules/sourceViewer/admin/AdminControls';
import PageNotFound from '@/components/page.not.found';

export default {
  metaInfo() {
    return {
      title: this.sourceDetailsState
        ? this.sourceDetailsState.title_en || this.sourceDetailsState.title_ch
        : 'Source viewer',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'View and translate Chinese records with the My China Roots Source Viewer, featuring our point-and-click dictionary feature.',
        },
      ],
    };
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        sourceViewerPage.onRouteQueryChange(toQuery, fromQuery, this);
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name && !['login', 'register', 'zupu-viewer'].includes(from.name)) {
        vm.previousRoute = {name: from.name, params: {...from.params}, query: {...from.query}};
      }
    });
  },
  data() {
    return {
      sourceId: this.$route.params.sourceId,
      previousRoute: null,
      previousRouteToLabel: {
        'my-zupus': 'My Zupus',
        'search-tool-zupu-detail': 'Record Details',
        'source-detail': 'Record Details',
        'search-all-records': 'Search Results',
        'search-burial-records': 'Search Results',
        'search-books-records': 'Search Results',
        'search-immigration-records': 'Search Results',
        'person-records-detail': 'Record Details',
      },
      defaultPreviousPageLabel: 'Record Details',
      currentPageNumber: parseInt(this.$route.query.page) || 1,
      searchValue: this.$route.query.search,

      isRecordOffline: false,
      isRecordNotExist: false,
    };
  },
  beforeMount() {
    sourceViewerPage.onBeforeMount(this);
  },
  created() {
    this.$store
      .dispatch('fetchSourceDetailsAction', {id: this.sourceId, exclude: 'mentions_count,summary_preview,summary'})
      .then(source => {
        sourceViewerPage.trackPageOpen(source, this);
        if (source.is_publicly_available && !this.userIsLoggedInState) {
          this.registerWall();
          return;
        }
        if (!source.user_has_full_access) {
          return this.subscriptionWall();
        }
        if (!source.has_images) {
          this.$router.replace(this.detailsRoute);
          return;
        }
        sourceViewerPage.onSourceDetailsFetch(source, this);
      })
      .catch(error => {
        sourceViewerPage.onSourceDetailsFetchError(error, this);
      });
  },
  computed: {
    ...mapGetters([
      'sourceImagesState',
      'sourceImagesOcrState',
      'sourceImageLoadingState',
      'sourceImageOcrLoadingState',
      'sourceImagesSearchResultsState',
      'sourceImagesSearchLoadingState',
      'sourceImagesSearchMetaState',
      'sourceDetailsLoadingState',
      'sourceDetailsState',
      'userIsStaffState',
      'userIsLoggedInState',
      'userIsSubscribedState',
    ]),
    currentPage() {
      return this.sourceImagesState[this.currentPageNumber - 1] || {};
    },
    currentPageOcrData() {
      return this.sourceImagesOcrState[this.currentPageNumber - 1] || {};
    },
    defaultPreviousRoute() {
      if (this.detailsLoaded) {
        return this.detailsRoute;
      }
      return {name: 'source-detail', params: {sourceId: this.$route.params.sourceId}};
    },
    detailsRoute() {
      return this.sourceDetailsState.zupu_id ? this.zupuDetailsRoute : this.sourceDetailsRoute;
    },
    sourceDetailsRoute() {
      return {name: 'source-detail', params: {sourceId: this.sourceId}};
    },
    zupuDetailsRoute() {
      return {name: 'search-tool-zupu-detail', params: {zupuId: this.sourceDetailsState.zupu_id}};
    },
    searchResults() {
      return this.sourceImagesSearchResultsState.map(item => ({
        ...item,
        isCurrentPage: this.currentPageNumber === item.priority,
      }));
    },
    detailsLoaded() {
      return Boolean(!this.sourceDetailsLoadingState && this.sourceDetailsState.id);
    },
    currentPageId() {
      return this.$route.query.page_id;
    },
    showOcrButton() {
      return this.userIsStaffState || this.userIsSubscribedState;
    },
    previousPageLabel() {
      return sourceViewerPage.getPreviousPageLabel(this);
    },
  },
  methods: {
    onSearchFromBar(value) {
      this.onSearch({value, page: 1});
    },
    onSearch({value, page}) {
      sourceViewerPage.onSearch(value, page, this);
    },
    searchResultClick(pageNumber) {
      this.onChangePage({pageNumber, pageNavigateName: 'search result click'});
    },
    handlePageChange(pageNumber) {
      sourceViewerPage.handlePageChange(pageNumber, this);
    },
    onChangePage({pageNumber, pageNavigateName}) {
      this.handlePageChange(pageNumber);
      AnalyticsAmplitudeHandler.trackViewerNavigatePage(pageNavigateName, pageNumber);
      this.goToPage(pageNumber);
    },
    onSelectOcrType(ocrType) {
      this.fetchSourceImageWithOcr(true, ocrType);
    },
    saveToQuery(params) {
      const needReplace = !this.$route.query.page && params.page;
      let query = {...this.$route.query, ...params};
      needReplace ? this.$router.replace({query}).catch(() => {}) : this.$router.push({query}).catch(() => {});
    },
    removeFromQuery(name) {
      let query = omit(this.$route.query, name);
      this.$router.replace({query}).catch(() => {});
    },
    goToPage(pageNumber) {
      this.currentPageNumber = pageNumber;
      this.fetchSourceImageWithOcr();
      this.$refs['viewer-page'].resetRotate();
    },
    fetchSourceImageWithOcr: debounce(function (skipCache, ocrType) {
      sourceViewerPage.fetchSourceImageWithOcr(skipCache, ocrType, this);
    }, 300),
    fetchSearchResults(offset) {
      this.$store
        .dispatch('searchSourceContentAction', {sourceId: this.sourceId, query: this.searchValue, offset: offset || 0})
        .then(res => {
          AnalyticsAmplitudeHandler.trackViewerSearchSubmit(
            this.searchValue,
            this.sourceImagesSearchMetaState.total_count
          );
          this.$store.dispatch('fetchPreviewForSearchSourceContentAction', {
            sourceId: this.sourceId,
            search: this.searchValue,
          });
        });
    },
    clearSearch() {
      sourceViewerPage.clearSearch(this);
    },
    onGetHelpClick() {
      this.$router.push({name: 'translation', query: {from: decodeURIComponent(this.$route.fullPath)}});
      AnalyticsAmplitudeHandler.trackSourceViewerGetHelp(
        this.sourceDetailsState.id,
        this.sourceDetailsState.title_en || this.sourceDetailsState.title_ch
      );
    },
    subscriptionWall() {
      const toRoute = getSubscriptionWallRoute(this.$route.fullPath, '');
      const fullPath = this.$router.resolve(toRoute).href;
      const pageName = this.previousRoute ? this.previousRoute.name : this.$route.name;
      AnalyticsAmplitudeHandler.trackHitPaywallAction('View source viewer', toRoute.name, fullPath, pageName);
      this.$router.replace(toRoute);
    },
    registerWall() {
      this.$router.replace({name: 'register', query: {redirect: this.$route.fullPath}});
    },
    getThumbnailSrc(priority) {
      return getSourceImageThumbnailResolveURL(this.sourceId, priority);
    },
  },
  components: {
    PreviewUnavailableLivingPerson,
    PageRecordOffline,
    PageNotFound,
    PreviewUnavailable,
    SourceSearchResultsContainer,
    BaseSourceDetailsInfo,
    ViewerPage,
    AddMentionModal,
    AdminControls,
    McrButton,
    SaveZupuButton,
  },
};
</script>

<style lang="scss" scoped>
.description-text {
  padding: 25px;
  overflow: auto;
  height: 100%;
  word-break: break-word;
  box-sizing: border-box;
  white-space: pre-wrap;
}
</style>
